import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import { randomNumber } from "../utils/miscFunctions";
import { PageProps } from "gatsby";
import Seo from "../components/Seo";
import {
  Breadcrumb,
  Typography,
  Anchor,
} from "@cncxt/imodium-component-library";
import RichTextComp from "../components/RichTextComp";
import styles from "../styles/wtb.module.scss";

interface pageInterface extends PageProps {
  pageContext: {
    uuid: string;
    urlPath: string;
    title: string;
    pageType: string;
    components: string;
    footerData: string;
    meta: {
      property_key_name: string;
      property_key_value: string;
      content: string;
    }[];
    canonical: {
      ref: string;
      href: string;
    };
    hreflang: {
      rel: string;
      hreflang: string;
      href: string;
    }[];
    breadcrumbs: {
      title: string;
      link: string;
    }[];
    wtbData: {
      image: {
        src: string;
      };
      link: {
        href: string;
        target: string;
      };
    }[];
  };
}

const WTBPage = (data: pageInterface): JSX.Element => {
  const dataContext = data.pageContext;
  const pathname = data.location.pathname;
  const pageTitle = dataContext.title;
  const footerData = dataContext.footerData;
  const componentData = JSON.parse(dataContext.components);
  const titlePane = componentData.find(comp => comp.type == "title_pane");
  const titleContent = titlePane.content ? titlePane.content : null;
  const breadcrumbList =
    dataContext.breadcrumbs !== null
      ? dataContext.breadcrumbs.map(path => {
          return { label: path.title, link: path.link };
        })
      : [];
  const wtbData = dataContext.wtbData ? dataContext.wtbData : [];
  return (
    <DefaultLayout siteFooterData={footerData}>
      <Seo
        metaArray={dataContext.meta}
        canonicalArray={dataContext.canonical}
        hreflangArray={dataContext.hreflang}
      />
      <div className={styles.contentWrapper}>
        {dataContext.breadcrumbs !== null && pathname !== "/" && (
          <Breadcrumb listItems={breadcrumbList} variation={"linear"} />
        )}
        {titleContent && (
          <div>
            <div className={styles.textWrapper}>
              <Typography variant="h1" color="ternary">
                {titleContent.title}
              </Typography>
            </div>
            <div className={styles.textWrapper}>
              <RichTextComp content={titleContent.description} width="100%" />
            </div>
            <div className={styles.wtbWrapper}>
              {wtbData.map((wtb, index) => (
                <div
                  key={index + randomNumber}
                  className={styles.imageContainer}
                >
                  <div className={styles.imageWrapper}>
                    <Anchor link={wtb.link.href} target={wtb.link.target}>
                      <img src={wtb.image.src ? wtb.image.src : ""} />
                    </Anchor>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </DefaultLayout>
  );
};

export default WTBPage;
